import React from 'react';
import { Box, Text } from 'styles';
import { Button } from 'components/button/Button';
import { Icon } from 'components/icon/Icon';
import * as S from './AlertStyle';
import { Dim } from '../Dim';

export function Alert({ open, setOpen, onClick, children, ...props }) {
  return (
    <Dim open={open}>
      <S.AlertContainer>
        <S.TitleBox>
          <Text $type="b24" $color="primary">
            {props.title || 'Info'}
          </Text>
          <Icon name="Close" onClick={() => setOpen()} />
        </S.TitleBox>
        <Box $padding="28px 0">{children}</Box>
        <Button $height={44} $width="100%" onClick={onClick}>
          OK
        </Button>
      </S.AlertContainer>
    </Dim>
  );
}
