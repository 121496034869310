import styled from 'styled-components';
import { Box, RowBox, slideUp } from 'styles';

export const AlertContainer = styled(Box)`
  background-color: white;
  border-radius: 8px;
  min-width: 280px;
  width: 516px;
  padding: 16px;

  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-name: ${slideUp};
  animation-fill-mode: forwards;

  @media ${({ theme }) => theme.device.tablet} {
    width: 80%;
    min-width: unset;
  }
`;

export const TitleBox = styled(RowBox)`
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #ededed;
`;
