import { keyframes } from 'styled-components';

export const slideUp = keyframes`
    from {
        transform: translateY(20px);
    }
    to {
        transform: translateY(0px);
    }
`;

export const slideDown = keyframes`
    from {
        transform: translateY(-20px);
    }
    to {
        transform: translateY(0px);
    }
`;

export const floating = keyframes`
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-30px);
  }
  100% {
    transform: translatey(0px);
  }`;
